import React from 'react';

function Email() {
  return (
    <React.Fragment>
      <h5>EMAIL</h5>
      <p><a href="mailto:montgomery@nexicom.net?subject=Hello!">montgomery@nexicom.net</a></p>
    </React.Fragment>
  );
}

export default Email;
