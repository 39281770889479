import React from 'react';

function Phone() {
  return (
    <React.Fragment>
      <h5>PHONE</h5>
      <p style={{ marginBottom: 0 }}>
        705 927 1256
        <br />
      </p>
    </React.Fragment>
  );
}

export default Phone;
